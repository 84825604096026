import { NotInterested } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, FormControlLabel, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useEffect, useRef } from 'react';
import LogitarApi from '../api/LogitarApi';
import CustomToolbar, { toolbarSlotProps } from '../components/CustomToolbar';
import ItemEdit, { units } from '../components/ItemEdit';
import { localeText } from '../misc/LocaleText';
import { contentBoxStyle } from '../styles/styles';
import { useUserConfig } from '../misc/UserConfig';
import PersistentDataGridPro from '../components/persistent/PersistentDataGridPro';
import Config from '../config/Config';
import FishIcon from '../assets/FishIcon';

const fishOptions = Config.getBranch() === 'konnekuljetus' || Config.getBranch() === 'softrain';
const icMod = Config.getModule("interclient");

/** @type {import("@mui/x-data-grid").GridColDef[]} */
const initColumns = [
    { field: 'id', headerName: 'ID' },
    { field: 'clientName', headerName: 'Asiakaskoodi', minWidth: 130 },
    { field: 'departureName', headerName: 'Lastaus' },
    { field: 'arrivalName', headerName: 'Purku' },
    { field: 'cargoLabel', headerName: 'Tuote' },
    { field: 'itemNumber', headerName: 'Nimikenumero' },
    { field: 'itemNumberLegacy', headerName: 'Vanha Nimikenumero' },
    { field: 'passive', headerName: 'Passiivinen', align: "center", renderCell: (params) => !params.row.active ? <NotInterested /> : null },
]
/** @type {import("@mui/x-data-grid").GridColDef} */
export const typeColumn = { field: 'itemType', headerName: 'Kalanimike', align: "center", valueGetter: (params) => params.row.itemType === 'fish' ? 'Kyllä' : 'Ei' ,
    renderCell: (params) => params.row.itemType === 'fish' ? <FishIcon /> : null };

/** @type {import("@mui/x-data-grid").GridColDef[]} */
const fishColumns = [
    { field: "fishType", headerName: "Kalakuorman tyyppi", minWidth: 140,
        valueGetter: (params) => params.value === "cargo" ? "Kuorma" : params.value === "wash" ? "Pesu" : null },
    { field: "fishReqLoadTime", headerName: "Lastauksen kesto", minWidth: 130 },
    { field: "fishReqUnloadTime", headerName: "Purun kesto", minWidth: 90 },
    { field: "fishReqWashTime", headerName: "Pesun kesto", minWidth: 90 },
    { field: "fishReqWashLocation", headerName: "Pesupaikka", minWidth: 90 },
    { field: "fishReqFishStatus", headerName: "Kalojen kunto", minWidth: 100 },
]

/** @type {import("@mui/x-data-grid").GridColDef[]} */
const icColumns = [
    { field: 'interAliases', headerName: 'Tilaajan nimike', minWidth: 150, valueFormatter: (params) => {
        /** @type {{name: string, displayName: string}[]} */
        const clients = icMod.clients;
        /** @type {{interClient: string, icItem: number}[]} */
        const value = params.value;
        if (!value || !Array.isArray(value)) return null;
        const list = value?.map(alias => `${clients?.find(c => c.name === alias.interClient)?.displayName}: ${alias.icItem}`);
        if (list && list?.length > 0) {
            return list.join(", ");
        }
        return null;
    }},
    { field: 'interClients', headerName: 'Alihankkijat', minWidth: 150, valueFormatter: (params) => {
        /** @type {{name: string, displayName: string}[]} */
        const clients = icMod.clients;
        /** @type {string[]} */
        const value = params.value;
        if (!value || !Array.isArray(value)) return null;
        const list = value?.map(ic => clients?.find(c => c.name === ic)?.displayName);
        if (list && list?.length > 0) {
            return list.join(", ");
        }
        return null;
    }},
]

const columns = fishOptions ? initColumns.toSpliced(1, 0, typeColumn) : initColumns;

export const  getHoursToTime = (params) => {
    if (params || params === 0) {
        let modifier = 60
        let minutes = Number(params.row.hours) * modifier
        const hours = Math.floor(minutes / 60)
        minutes = (minutes - hours * 60).toFixed(0)
        return `${hours}:${minutes > 9 ? minutes : "0" + minutes}`
    }
}

/** @type {import("@mui/x-data-grid").GridColDef[]} */
const initWidercolumns = [
    { field: 'id', headerName: 'ID' },
    { field: 'clientName', headerName: 'Asiakaskoodi', minWidth: 130 },
    { field: 'departureName', headerName: 'Lastaus' },
    { field: 'departureAddress', headerName: 'Lastauspaikan osoite' },
    { field: 'departureCoordinates', headerName: 'Lastauspaikan koordinaatti' },
    { field: 'arrivalName', headerName: 'Purku' },
    { field: 'arrivalAddress', headerName: 'Purkupaikan osoite' },
    { field: 'arrivalCoordinates', headerName: 'Purkupaikan koordinaatti' },
    { field: 'cargoLabel', headerName: 'Tuote' },
    { field: 'basicTypeName', headerName: 'Tyyppi', minWidth: 100 },
    { field: 'itemNumber', headerName: 'Nimikenumero', minWidth: 150 },
    { field: 'itemNumberLegacy', headerName: 'Vanha Nimikenumero', minWidth: 100 },
    { field: 'passive', headerName: 'Passiivinen', align: "center", renderCell: (params) => !params.row.active ? <NotInterested /> : null },
    { field: 'name', headerName: 'Nimike', minWidth: 250 },
    { field: 'shortName', headerName: 'Lyhennetty nimike', minWidth: 250 },
    { field: 'billingReason', headerName: 'Laskutusperuste', minWidth: 120, valueGetter: (params) => units.find(u => Number(u.value) === Number(params.value))?.label },
    { field: 'kilometres', headerName: 'Kilometrit', minWidth: 80 },
    { field: 'hours', headerName: 'Tunnit', valueGetter: getHoursToTime, minWidth: 80 },
    { field: 'warrantyLoad', headerName: 'Takuukuorma', minWidth: 100 },
    { field: 'reqCount', headerName: 'Kappalemäärä', minWidth: 110 },
    { field: 'reqM3', headerName: 'Kuutiot', minWidth: 60 },
    { field: 'reqTons', headerName: 'Tonnit', minWidth: 60 },
    { field: 'reqHours', headerName: 'Tunnit', minWidth: 60 },
    { field: 'reqWaitTime', headerName: 'Odotus', minWidth: 60 },
    { field: 'reqKm', headerName: 'Km', minWidth: 60 },
    { field: 'reqDetails', headerName: 'Lisätiedot', minWidth: 80 },
    { field: 'detailDesc', headerName: 'Lisätieto kuvaus', minWidth: 150 },
    { field: 'talenomProduct', headerName: 'Talenom tuotenro', minWidth: 150, renderCell: (params) => {
        const vars = params.row.talenomVariants;
        if (vars && vars.length > 0) {
            return <Tooltip title={vars.map(v => <Box>{v.product}: {v.name}</Box>)}>
                <span>{vars[0].product}+</span>
            </Tooltip>
        }
        else return params.value;
    }},
    { field: 'setMWh', headerName: 'MWh' },
    { field: 'notes', headerName: 'Kuvaus', minWidth: 3000 },
]

const widercolumns = (() => {
    let finalColumns = initWidercolumns;
    if (icMod.enabled) finalColumns.splice(29, 0, ...icColumns);
    if (fishOptions) finalColumns.splice(1, 0, typeColumn);
    if (fishOptions) finalColumns.splice(20, 0, ...fishColumns);
    return finalColumns;
})()

/** @type {{ model: import('@mui/x-data-grid').GridFilterModel | undefined, columnModel: import('@mui/x-data-grid').GridColumnVisibilityModel | undefined, buttonForNext: string }[]} */
export const fishFilters = [
    { 
        model: {items: []},
        columnModel: {},
        buttonForNext: "Vain kalanimikkeet" },
    { 
        model: {items: [{ field: "itemType", operator: "contains", value: "Kyllä" }]}, 
        columnModel: {},
        buttonForNext: "Ei kalanimikkeitä" 
    },
    { 
        model: {items: [{ field: "itemType", operator: "contains", value: "Ei" }]}, 
        columnModel: (() => {
            const resObj = {};
            fishColumns.forEach(c => resObj[c.field] = false);
            resObj["itemType"] = false;
            return resObj;
        })(),
        buttonForNext: "Näytä kaikki" 
    },
]

export default function Items() {
    /** @type {{config: import("../components/persistent/PersistentDataGridPro").UserConfigDataGrid}} */
    const userConfig = useUserConfig('items');

    const [rows, setRows] = React.useState([])
    const [popup, setPopup] = React.useState(false)
    const [item, setItem] = React.useState(null)

    //Count for handling grid update
    const [count, setCount] = React.useState(0)
    const [createNew, setCreateNew] = React.useState(false)
    const [showPassive, setShowPassive] = React.useState(userConfig.config.showPassive != null ? userConfig.config.showPassive : false);

    const [fishFilter, setFishFilter] = React.useState(0);

    //Previously used detail descriptions for itemEdit screen
    const detailOptions = useRef([])

    const apiRef = useGridApiRef();

    const searchParams = new URL(window.location.href).searchParams;
    const [expandList, setExpandList] = React.useState(userConfig.config.viewType === 'expanded' ? true : false);
    const [inProgressIcon, setInProgressIcon] = React.useState(false);

    const handleNewItem = () => {
        setItem({
            client: false,
            cargoType: false, active: true, arrival: null, departure: null, arrivalSub: null, departureSub: null,
            shortName: "", notes: "",
            name: "", reqTons: 1, reqHours: 0, reqCount: 0, hours: "",
            reqM3: 0, reqWaitTime: 0, billingReason: 1, reqDetails: 0, reqKm: 0,
            setMWh: 0, detailDesc: "", itemType: 'normal',
            fishReqFishStatus: 0, fishReqLoadTime: 0, fishReqUnloadTime: 0, fishReqWashTime: 0, fishReqWashLocation: 0,
        })
        setPopup(true)
        setCreateNew(true)
    }

    /**
     * Call items
     */
    useEffect(() => {
        let method = "list"
        if (expandList) {
            method = "widerlist"
        }

        setInProgressIcon(true);
        LogitarApi.getItems({ extent: method }).then((result) => {
            setInProgressIcon(false);
            if (!result.items) {
                return
            } else {
                console.log("Haetut items", result.items)
                setRows(result.items)
                const _details = new Set();

                result.items.forEach((item) => {
                    if (item.detailDesc)
                        _details.add(String(item.detailDesc).trim())
                })

                detailOptions.current = Array.from(_details)
                apiRef.current.autosizeColumns();
            }
        })
            .catch((err) => console.error(err))

    }, [count])

    useEffect(() => {
        setTimeout(() => {
            if (apiRef.current) {
                apiRef.current.autosizeColumns();
            }
        }, 250)
        apiRef.current.autosizeColumns(); // Autosize immediately too for showPassive changes
    }, [rows, apiRef.current, showPassive])

    useEffect(() => {

        if (searchParams.get("id")) {
            handlePopupItem({
                row: {
                    id: searchParams.get("id")
                }
            });
        }
        if (searchParams.has("passive")) {
            setShowPassive(true);
            userConfig.config = { ...userConfig.config, showPassive: true };
        }
    }, []);

    //Fetch item information when row selected and open popup
    const handlePopupItem = (params) => {

        LogitarApi.getItems({ id: params.row.id, extent: "allfile" }).then((result) => {
            if (result.status) {
                let item = result.items[0]
                setItem(item)
                setPopup(true)
                setCreateNew(false)
                console.log(item)
                console.log("Fetched: " + item.id)
            }
        })
            .catch((err) => console.error(err))
    }

    return (
        <Box sx={{
            ...contentBoxStyle, '& .MuiDataGrid-row': {
                cursor: "pointer"
            },
            display: 'flex', flexDirection: 'column', height: '100%', width: '100%',
            mt: -1
        }}>
            <PersistentDataGridPro
                userConfig={userConfig}
                apiRef={apiRef}
                rows={!showPassive ? rows.filter(e => e.active === 1) : rows.filter(e => e.active === 0)}
                columns={expandList ? widercolumns : columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                disableSelectionOnClick={true}
                density='compact'
                onRowClick={(params) => { handlePopupItem(params) }}
                slots={{ 
                    toolbar: () => <CustomToolbar 
                        userConfig={userConfig}
                        text={"Uusi Nimike"} 
                        onClick={handleNewItem}
                        passiveFilter={showPassive}
                        onPassiveFilter={(c) => {
                            setShowPassive(c);
                            userConfig.config = { ...userConfig.config, showPassive: c };
                            apiRef.current.setColumnVisibility("passive", c);
                        }}
                        expandList={expandList}
                        onExpandList={(c) => {
                            setExpandList(c);
                            userConfig.config = { ...userConfig.config, viewType: c ? 'expanded' : 'normal' }
                            setCount(count + 1);
                        }}
                        customElement={fishOptions && 
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                            Vaihda rajausta:
                            <Button variant='outlined' size='small' sx={{ width: 160 }}
                                onClick={() => {
                                    const nextFishFilter = fishFilter < (fishFilters.length - 1) ? fishFilter + 1 : 0;
                                    setFishFilter(nextFishFilter);
                                    setTimeout(() => {
                                        apiRef.current.setFilterModel(fishFilters[nextFishFilter].model);
                                        apiRef.current.setColumnVisibilityModel(fishFilters[nextFishFilter].columnModel);
                                    });
                                }}
                            >{fishFilters[fishFilter].buttonForNext}</Button>
                            </Box>
                        }
                        hidePrinting
                    />
                }}
                localeText={localeText}
                initialState={{
                    sorting: {
                        sortModel: [{ field: "id", sort: "asc" }]
                    },
                    columns: {
                        columnVisibilityModel: { passive: showPassive }
                    }
                }}
                loading={!rows[0]}
                slotProps={toolbarSlotProps}
            />

            {popup &&
                <ItemEdit editItem={item} detailOptions={detailOptions.current} popupOpen={setPopup} newItem={createNew} rowUpdate={setCount}></ItemEdit>
            }
        </Box>
    )
}