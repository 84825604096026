import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Box } from "@mui/material";
import ReportCard from "../ReportCard";
import { BarChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import { fi } from "date-fns/locale";
import { format } from "date-fns";
import { FuelType } from "../FuelPriceCard";


export const reportFuelTypes = [
    { label: "Kaikki", value: FuelType.UNKNOWN, name: "all", unit: "*" },
    { label: "Diesel", value: FuelType.diesel, name: "diesel", unit: "L" },
    { label: "Kaasu", value: FuelType.gas, name: "gas", unit: "kg" },
    { label: "Sähkö", value: FuelType.electric, name: "electric", unit: "kWh" },
    { label: "Vety", value: FuelType.hydrogen, name: "hydrogen", unit: "kg" },
    { label: "Biodiesel", value: FuelType.biodiesel, name: "biodiesel", unit: "L" },
]

/**
 * 
 * @param {{
 *  data: import("../../views/FuelReports").FuelReportData,
 *  width?: number,
 *  mode?: "total" | "monthly" | "yearly" | "table",
 *  fuelType?: { value: string, label: string, unit: string },
 * }} props 
 * @returns 
 */
export default function FuelConsumptionReport(props) {

    const mode = props.mode ? props.mode : "total";

    const [reportData, setReportData] = useState([]);
    const [seriesKeys, setSeriesKeys] = useState([]);

    let totalFuel = 0; 
    let totalKm = 0;
    let fuelTypeLabel = ""  

    useEffect(() => {
        if (!props.data) {
            setReportData([]);
            return;
        }
        let mData = [];
        let sks = [];
        switch (mode) {
            case "total":
                setReportData(props.data.total);
                break;
            case "monthly":
                props.data.monthly.forEach(y => {
                    const ft = reportFuelTypes.find(t => t.value === y.fuelTypes[0]);
                    y.months.forEach(m => {
                        let md = mData.find(e => e.month === m.month);
                        if(!md) {
                            md = {
                                month: m.month
                            }
                            mData.push(md);
                        }

                        md[`${y.vehicle.id}:${y.vehicle.licenseNumber}`] = (m.distance > 0) ? ((m.fuel / m.distance) * 100) : 0;
                    });
                    if(!sks.includes(y.vehicle.id)) {
                        sks.push({
                            dataKey: `${y.vehicle.id}:${y.vehicle.licenseNumber}`, label: `${y.vehicle.id}:${y.vehicle.licenseNumber}`,
                            valueFormatter: (v) => `${v.toFixed(2)} ${ft && ft.unit ? ft.unit : "*"}/100km`
                        });
                    }
                })
                setReportData(mData);
                setSeriesKeys(sks);
                break;
            case "yearly":
                props.data.yearly.forEach(y => {
                    const ft = reportFuelTypes.find(t => t.value === y.fuelTypes[0]);
                    y.years.forEach(m => {
                        let md = mData.find(e => e.year === m.year);
                        if(!md) {
                            md = {
                                year: m.year
                            }
                            mData.push(md);
                        }

                        md[`${y.vehicle.id}:${y.vehicle.licenseNumber}`] = (m.distance > 0) ? ((m.fuel / m.distance) * 100) : 0;
                    });
                    if(!sks.includes(y.vehicle.id)) {
                        sks.push({
                            dataKey: `${y.vehicle.id}:${y.vehicle.licenseNumber}`, label: `${y.vehicle.id}:${y.vehicle.licenseNumber}`,
                            valueFormatter: (v) => `${v.toFixed(2)} ${ft && ft.unit ? ft.unit : "*"}/100km`
                        });
                    }
                })
                setReportData(mData);
                setSeriesKeys(sks);
                break;
            case "table":
                console.log("Datan asetus",  props.data.total)
                setReportData(props.data.total); 
                break;
            default:
                setReportData([]);
                break;
        }
    }, [props.data])

    if (mode === "total") {
        return <ReportCard
            title={"Kokonaiskulutus"}
            // subtitle={"Kokonaiskulutus"}
            width={props.width}
        >
            {
                reportData.length > 0 &&
                <BarChart
                    xAxis={[{
                        id: 'vehicle-axis',
                        scaleType: 'band',
                        data: reportData.map(e => `${e.vehicle.id}:${e.vehicle.licenseNumber}`),
                        tickLabelInterval: () => true,
                    }]}

                    series={[{
                        data: reportData.map(e =>
                            (e.distance > 0) ? ((e.fuel / e.distance) * 100) : 0),
                        valueFormatter: (v) => `${v.toFixed(2)} ${props.fuelType ? props.fuelType.unit : "*"}/100km`,
                    }]}
                    width={props.width || 600}
                    height={300}
                    bottomAxis={{
                        axisId: 'vehicle-axis',
                        labelStyle: {
                            fontSize: 14,
                        },
                        tickLabelStyle: {
                            translate: "0px -8px",
                            angle: 60,
                            textAnchor: 'start',
                            fontSize: 10,
                        },
                    }}
                    margin={{
                        bottom: 60
                    }}
                />
            }
        </ReportCard>
    }
    else if (mode === "monthly") {
        return <ReportCard
            title={"Kuukausittainen kulutus"}
            // subtitle={"Kuukausittainen kulutus"}
            width={props.width}
        >
            {
                reportData.length > 0 &&
                <BarChart
                    dataset={reportData}
                    xAxis={[{
                        id: 'vehicle-axis',
                        scaleType: 'band',
                        dataKey: 'month',
                        tickLabelInterval: () => true,
                        valueFormatter: (v) => format(new Date(v), 'LLL yyyy', { locale: fi }),
                    }]}
                    series={seriesKeys}
                    width={props.width || 600}
                    height={300}
                    bottomAxis={{
                        axisId: 'vehicle-axis',
                        labelStyle: {
                            fontSize: 14,
                        },
                        tickLabelStyle: {
                            translate: "0px -8px",
                            angle: 60,
                            textAnchor: 'start',
                            fontSize: 10,
                        },
                    }}
                    margin={{
                        bottom: 60
                    }}
                    slotProps={{
                        legend: { hidden: seriesKeys.length > 10 }
                    }}
                />
            }
        </ReportCard>
    }
    else if (mode === "yearly") {
        return <ReportCard
            title={"Vuosittainen kulutus"}
            // subtitle={"Vuosittainen kulutus"}
            width={props.width}
        >
            {
                reportData.length > 0 &&
                <BarChart
                    dataset={reportData}
                    xAxis={[{
                        id: 'vehicle-axis',
                        scaleType: 'band',
                        dataKey: 'year',
                        tickLabelInterval: () => true,
                        valueFormatter: (v) => format(new Date(v), 'yyyy', { locale: fi }),
                    }]}
                    series={seriesKeys}
                    width={props.width || 600}
                    height={300}
                    bottomAxis={{
                        axisId: 'vehicle-axis',
                        labelStyle: {
                            fontSize: 14,
                        },
                        tickLabelStyle: {
                            textAnchor: 'start',
                            fontSize: 10,
                        },
                    }}
                    slotProps={{
                        legend: { hidden: seriesKeys.length > 10 }
                    }}
                />
            }
        </ReportCard>
    }
    else if(mode === "table") {
        return (

        <ReportCard
            title={"Kulutus"}
            // subtitle={"Taulukko"}
            width={props.width}
            scrollX
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Auto</TableCell>
                        <TableCell>Määrä {props.fuelType ? props.fuelType.unit : "*"}</TableCell>
                        <TableCell>Ed. km</TableCell>
                        <TableCell>Viim. km</TableCell>
                        <TableCell>Matka km</TableCell>
                        <TableCell>{props.fuelType ? props.fuelType.unit : "*"}/100 km</TableCell>
                        <TableCell>Käyttövoima</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        reportData.map((e, i) => {

                            totalFuel += Number(e.fuel); 
                            totalKm += Number(e.distance);
                            const fts = reportFuelTypes.filter(rft => e.fuelTypes.some(ft => ft === rft.value));
                            fuelTypeLabel = ""
                            if (fts.length > 0) {
                                fuelTypeLabel = fts.map(ft => ft.label).join("/");
                            }

                            return <TableRow key={i}>
                                <TableCell size="small">{e.vehicle.id}:{e.vehicle.licenseNumber}</TableCell>
                                <TableCell size="small">{e.fuel}</TableCell>
                                <TableCell size="small">{e.minKm}</TableCell>
                                <TableCell size="small">{e.maxKm}</TableCell>
                                <TableCell size="small">{e.distance}</TableCell>
                                <TableCell size="small">{(e.distance > 0) ? ((e.fuel / e.distance) * 100).toFixed(2) : 0}</TableCell>
                                <TableCell size="small">{fuelTypeLabel}</TableCell>
                            </TableRow>
                        })
                    }

                    {/* Total */}
                    <TableRow>
                        <TableCell><Typography fontWeight="bold">Yht.</Typography></TableCell>
                        <TableCell><Typography fontWeight="bold">{totalFuel}</Typography></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell><Typography fontWeight="bold">{totalKm}</Typography></TableCell>
                        <TableCell><Typography fontWeight="bold">{(totalKm > 0) ? ((totalFuel/totalKm) * 100).toFixed(2): 0}</Typography></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </ReportCard>
        )
    }
}