import { Cancel, Clear, Delete, Info, Save, Undo } from '@mui/icons-material';
import DescriptionIcon from '@mui/icons-material/Description';
import { Autocomplete, Box, BoxProps, Button, IconButton, LinearProgress, Popover, PopoverPosition, Stack, SxProps, TextField, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, KeyboardEvent, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import LogitarApi from "../api/LogitarApi";
import { LogitarItem } from "../api/LogitarApiTypes";
import { CustomPopper } from "../misc/InternalFeatures";
import { DragItem, PlanningItem, PlanningJobInfo, PlanningRowIC, PlanningSlot } from "../misc/LogitarTypes";
import { PLANNING_SLOT_INITIAL_COUNT, SendingContext, UpdateContext } from "../views/Planning";
import AlertPop from "./AlertPop";
import PlanningSlotTooltip from './PlanningSlotTooltip';


const carHeaderStyle: SxProps = {
    minWidth: 130,
    fontSize: 12,
    mt: 1,
    mr: 1,
    fontWeight: 'bold',

}

const carTextStyle: any = {
    mb: 0,
    width: 110,
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: "black"
        }
    },
    height: 48,
    mr: 0
}

const rowStyle: SxProps = {
    display: 'flex',

    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    height: "auto",
    gap: "4px 8px",
}

const itemOptionStyle: SxProps = {
    flex: 1,
    maxWidth: 800,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
}

const normalize = (n: number) => {
    if (n > 12) {
        return 100;
    }
    return n / 12 * 100;
}

export const logoPaths: { [key: string]: { path: string; style?: any; }; } = {
    "softrain": {
        path: "./logos/logo-softrain.png",
    },
    "konnekuljetus": {
        path: "./logos/logo-konnekuljetus.png"
    },
    "tarkkala": {
        path: "./logos/logo-tarkkala.svg"
    },
    "metsanen": {
        path: "./logos/logo-metsanen.svg"
    }
}

export const logoPathsSmall: { [key: string]: { path: string; style?: any; }; } = {
    "softrain": {
        path: "./logos/small/logo-softrain-small.png"
    },
    "konnekuljetus": {
        path: "./logos/small/logo-konnekuljetus-small.png"
    },
    "tarkkala": {
        path: "./logos/small/logo-tarkkala-small.svg"
    },
    "metsanen": {
        path: "./logos/small/logo-metsanen-small.svg"
    }
}
const reqs: { [key: string]: { inJob: string, label: string } } = {
    "reqTons": { inJob: "tons", label: "Tonnit", },
    "reqM3": { inJob: "m3", label: "Kuutiot", },
    "reqCount": { inJob: "count", label: "Kpl", },
    "reqHours": { inJob: "hours", label: "Tunnit" },
    "reqWaitTime": { inJob: "waitTime", label: "Odotus", },
    "reqKm": { inJob: "kilometres", label: "Kilometrit", },
    "reqDetails": { inJob: "jobDetails", label: "Lisätiedot" },
    "talenomVariants": { inJob: "talenomVariant", label: "Laji" },
}

function PlanningRowSlot(props: { slot: PlanningSlot, dragItem?: DragItem | null, interClient?: string, onStartDrag: (e: DragEvent) => void }) {

    const { slot } = props;
    const interClient = props.interClient;

    const [info, setInfo] = useState<string | null>(slot.job?.planDetails || null);
    const [job, setJob] = useState<PlanningJobInfo | null>(slot.job ? { ...slot.job } : null);

    const [isSelected, setIsSelected] = useState(false);
    const autocompleteRef = useRef<any>();
    const { enqueueSnackbar } = useSnackbar();

    const update = useContext(UpdateContext);
    const sending = useContext(SendingContext);

    const [options, setOptions] = useState<any[]>([]);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [contextMenu, setContextMenu] = useState<PopoverPosition | null>(null);

    const [deleteConf, setDeleteConf] = useState(false);

    const searchTimeout = useRef<NodeJS.Timeout | null>(null);
    const [optionsLoading, setOptionsLoading] = useState(false);

    const theme = useTheme();

    const [disabled, setDisabled] = useState<boolean>(false);

    useEffect(() => {
        if (!interClient) return
        if (!props.dragItem?.item.interClients?.includes(interClient))  {
            setDisabled(true);
            return
        }
        setDisabled(false);
    }, [props.dragItem])

    useEffect(() => {
        setJob(props.slot.job ? { ...props.slot.job } : null);

        if (!job) return;

        // Set appropriate planDetails
        if (props?.slot?.job?.draft?.planDetails != null) {
            setInfo(props.slot.job.draft.planDetails);
        } else if (props?.slot?.job?.planDetails != null) {
            setInfo(props.slot.job.planDetails);
        } else {
            setInfo(job?.planDetails);
        }

        if (sending)
            sending(() => false)

    }, [props.slot, props.slot.job])

    useEffect(() => {
        if (isSelected && autocompleteRef.current) {
            autocompleteRef.current.focus();
        }
    }, [isSelected])

    const clearCreatedPlan = (send: boolean, slot: PlanningSlot) => {

        if (!send) {
            setDeleteConf(false);
            return;
        }

        if (!slot.job) return;

        let d: any = {
            id: slot.job.id,
            ">deleted": true
        }
        if (interClient) {
            d = {
                ...d,
                icOrder: true,
                interClient: interClient
            }
        }
        sending(() => true)

        LogitarApi.setJobs(d).then((result) => {
            setDeleteConf(false);
            if (!slot.job) return;

            console.log(result);
            update(d);
            //If clear successful, reset this slot
            if (slot.job.state === 0) {
                setJob(null);
            } else {
                setJob({ ...slot.job, draft: { ">deleted": true } });
            }
            setInfo(null);
            

        })
            .catch((err) => console.error(err))
            .finally(() => sending(() => false))
    }

    const undoDelete = () => {
        if (!job)
            return;

        let d = {
            id: job.id,
            ">deleted": false
        }

        LogitarApi.setJobs(d).then((result) => {
            enqueueSnackbar("Kuorma poistettu");
        })
            .catch((err) => {
                enqueueSnackbar("Kuorman poisto epäonnistui", { variant: "error" });
            });
    }

    //Send "details" as soon as its saved
    const handleInfoSave = (del: boolean = false) => {
        if (!job)
            return;

        let d: any = {
            id: job.id,
            planDetails: del ? null : info
        }
        if (interClient) {
            d = {
                ...d,
                icOrder: true,
                interClient: interClient
            }
        }

        LogitarApi.setJobs([d]).then((result) => {
            console.log(result)
            handleInfoClose()
        })
            .catch((err) => console.error(err))

    }

    const handleJobKeyInput = (event: KeyboardEvent) => {
        if (event.key === "Tab" || event.key === "Enter") {
            event.preventDefault();
            event.stopPropagation();
            if (autocompleteRef.current.value) {
                const nextSlotExists = slot.slot < PLANNING_SLOT_INITIAL_COUNT - 1;
                const searchId = !isNaN(autocompleteRef.current.value) ? autocompleteRef.current.value : null;
                /** @ts-ignore must be ignored because getItems params are defined wrong*/
                if (searchId) LogitarApi.getItems({id: Number(searchId)}).then(result => {
                        if (result.status && result.items.length > 0) {
                            selectChange(null, { item: result.items[0] }, slot)
                            if (nextSlotExists) {
                                const nextElement = document.evaluate(`//*[@data-testid="planning-slot-ic"][@data-slotinfo="${interClient};${slot.slot + 1}"]`, document).iterateNext() as HTMLElement;
                                nextElement.click();
                            }
                        }
                }).catch(err => {
                    enqueueSnackbar(`Nimikettä ${searchId} ei löytynyt`, { variant: "error" });
                    console.error(err);
                });
            }
        }
    }

    const handleInfoClose = () => {
        setAnchorEl(null);
    }

    const selectChange = (event: React.DragEvent | React.ChangeEvent | null, item: DragItem | null, slot: PlanningSlot) => {
        
        console.log(event, item, slot);

        if (!item)
            return;

        if (item.item.itemType === "fish") {
            enqueueSnackbar("Kalatilauksia ei voi tehdä alihankintaprosessista", { variant: "warning", autoHideDuration: 5000 }); 
            return;
        }

        sending(() => true);

        let itemId = item.item.id;

        /*TODO:
        if (icJobData) {
            itemId = null;
        }
        */

        if(event)
            event.preventDefault();

        let tempPlan: any = {
            id: item.job ? item.job.id : undefined,
            date: slot.date,
            shift: slot.shift,
            item: item.item.id,
            vehicle: slot.vehicle,
            slot: slot.slot,
            ">forceUpdate": (item.job && item.job.id && item.job.state === 0) ? true : false,
            /* TODO:
            interClient: values.icJobData ? values.icJobData.interClient : undefined,
            icItem: values.icJobData ? values.icJobData.icItem : undefined,
            id: values.icJobData ? values.icJobData.id : undefined
            */
        }

        if (interClient) {
            // Set IC values
            tempPlan.interClient = interClient;
            // Set IC item
            tempPlan.icItem = itemId;
            // Important, if not set, icCheckState will be null!
            tempPlan.checkState = "0";
            // Remove item
            delete tempPlan.item;
            // Remove vehicle if exists
            delete tempPlan.vehicle;
            // Remove "client"
            delete tempPlan.client;

            // Add icOrder flag
            tempPlan.icOrder = true;
        }

        if (autocompleteRef.current)
            autocompleteRef.current.blur();

        console.log(tempPlan);

        LogitarApi.setJobs([tempPlan]).then((result) => {
            setOptions([]);
            update(tempPlan);
        }).catch((err) => {
            console.error(err)
            if (err.error === 0x48) {
                enqueueSnackbar(`Nimike: ${tempPlan.icItem}:"${item.item.shortName}" ei ole sallittu alihankkijalle`, { variant: "error" });
            }
        }).finally(() => {
            sending(() => false);
        })
    }

    const handleRowContextMenu = (e: React.MouseEvent) => {
        e.preventDefault();
        setContextMenu({ top: e.clientY, left: e.clientX })
    }

    const searching = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {

        if (e.target.value.trim() === "") {

        } else {
            if (searchTimeout.current) clearTimeout(searchTimeout.current);
            searchTimeout.current = setTimeout(() => {
                setOptionsLoading(true);
                /** @ts-ignore must be ignored because getItems params are defined wrong*/
                LogitarApi.getItems({ search: String(e.target.value) })
                    .then((result) => {
                        if (result.items.length === 0) {

                        } else {
                            setOptions(result.items.filter((v: LogitarItem) => v.itemType === 'normal').map((value: LogitarItem) => {
                                return { ...value }
                            }))
                        }
                    })
                    .catch((e) => console.error(e))
                    .finally(() => {
                        setOptionsLoading(false);
                        searchTimeout.current = null;
                    })

            }, 500)

            return () => clearTimeout(searchTimeout.current || undefined)
        }

    }, [searchTimeout.current])

    const isDeleted = !!job && job.draft && job.draft[">deleted"] ? true : false;

    if (!!job && !isDeleted) {

        let logoPath = "";
        let logoStyle = {};

        /* TODO:
        if (pInfo.interClient && logoPaths[pInfo.interClient]) {
            logoPath = logoPaths[pInfo.interClient].path;
            logoStyle = logoPaths[pInfo.interClient].style || {};

        }
        */

        const icBg: SxProps = {

            "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: `linear-gradient(to bottom right, inherit 50%, transparent 50%)`,
                zIndex: -1
            },
            position: "relative",
            height: "100%",
            background: `linear-gradient(to bottom right, inherit 50%, transparent 50%), url('${logoPath}') no-repeat bottom right`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            ...logoStyle
        }

        let slotStyle = "default-red";

        if(job.state === 0 || (!!job.draft && !Array.isArray(job.draft) && Object.keys(job.draft).length > 0)) {
            // Red job if is unsent or any draft is set
            slotStyle = "default-red";
        }
        else if(job.state === 1) {
            // Yellow job if sent
            slotStyle = "default-yellow";
        }
        else if(job.state === 2) {
            // Green job if seen
            slotStyle = "default-green";
        }
        else if(job.state === 3) {
            // Blue job if loaded
            slotStyle = "default-blue";
        }
        else if(job.state === 4) {
            // Grey job if completed
            slotStyle = "";
        }

        // carTextStyle can't be null but TS seems to think it can be
        if (!carTextStyle)
            return null;

        const isDraggable = !(job.jobSplit && job.jobSplit.date === slot.date && job.jobSplit.shift === slot.shift) && !props.onStartDrag;

        return (
            <Box
                sx={{
                    ...carTextStyle, display: "flex", flexDirection: "row", pr: 0,
                    "& .MuiFormHelperText-root": { width: "130px", ml: 0.5 },
                    "& .MuiOutlinedInput-root": { pr: 0.5 },
                }}
                onContextMenu={handleRowContextMenu}
                component={"div"}
            >
                <Tooltip sx={{ fontSize: 22 }} placement='right' title={<PlanningSlotTooltip job={job}/>}>
                    <Box
                        data-testid="planning-slot-ic"
                        data-slotinfo={`${interClient};${slot.slot}`}
                        sx={{
                            zIndex: 1,
                            background: job.state > 3 ? theme.palette.background.paper : undefined,
                            backgroundImage: job.state > 3 && theme.palette.mode === "dark" ? // Match the paper background
                                "linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1))" : undefined,
                            borderWidth: 1,
                            borderColor: theme.palette.mode === "light" ? "#888" : "#ccc",
                            borderStyle: 'solid',
                            borderRadius: 1,
                            px: 0.5,
                            py: 0.1,
                            height: carTextStyle.height,
                            width: carTextStyle.width - 24,
                            flexDirection: "row",
                            display: "flex",
                            textAlign: 'center',
                            ...(job.interClient ? icBg : {})
                        }}
                        className={!isDeleted ? slotStyle : "default-red"}
                        draggable={isDraggable}
                        onDragStart={(e) => {
                            if(!isDraggable) return;

                            props.onStartDrag(e as unknown as DragEvent)
                        }}
                    >
                        <Typography
                            sx={{ fontSize: "0.68rem", overflow: "hidden", overflowWrap: "break-word", cursor: "pointer" }}
                            onClick={() => {
                                // Open new tab in `items?id=${pInfo.item.id}`
                                window.open(`items?id=${job.item.id}`, "_blank");
                            }}
                        >
                            {
                                isDeleted &&
                                <s>
                                    {job.item.id}:{job.item.shortName}
                                </s>
                            }
                            {
                                !isDeleted &&
                                <>
                                    {job.item.id}:{job.item.shortName}
                                </>
                            }
                        </Typography>

                    </Box>
                </Tooltip>
                <Box sx={{ display: "flex", flexDirection: "column" }}>

                    <Clear sx={{ cursor: "pointer" }} onClick={() => setDeleteConf(true)}></Clear>

                    <div className={info && info.length > 0 ? 'default-green' : ""} style={{ borderRadius: 3, height: "50%" }}>
                        <DescriptionIcon onClick={(e) => { setAnchorEl(e.currentTarget) }} sx={{ cursor: "pointer" }}></DescriptionIcon>
                    </div>

                </Box>

                <Popover
                    sx={{ ml: 3, borderRadius: 1 }}
                    open={anchorEl !== null}
                    anchorEl={anchorEl}
                    anchorReference="anchorEl"
                    onClose={handleInfoClose}
                >
                    <Stack direction={"column"} gap={1} sx={{ px: 2, pt: 1, pb: 1.5, width: 450, position: "relative" }}>
                        <Typography variant="h6">Kuorman lisätiedot</Typography>
                        <IconButton sx={{ position: "absolute", top: 8, right: 8 }} onClick={() => handleInfoClose()}>
                            <Clear />
                        </IconButton>
                        <TextField onChange={(e) => setInfo(e.target.value)} value={info || ""} multiline rows={3} sx={{ pb: 1, width: "100%" }} />
                        <Box sx={{ display: "flex", flexDirection: "row", gap: 2, justifyContent: "space-between" }}>
                            <Button variant='contained' fullWidth color="inherit" startIcon={<Cancel />} onClick={() => handleInfoClose()}>Peruuta</Button>
                            <Button variant='contained' fullWidth color="error" startIcon={<Delete />} onClick={() => handleInfoSave(true)}>Poista</Button>
                            <Button variant='contained' fullWidth color="primary" startIcon={<Save />} onClick={() => handleInfoSave()}>Tallenna</Button>
                    </Box>
                    </Stack>
                </Popover>

                {deleteConf &&
                    <AlertPop
                        onClose={(action) => clearCreatedPlan(action, slot)}
                        cancellable={true}
                        title={"Haluatko varmasti poistaa kuorman?"}
                        content={
                            <div>
                                <PlanningSlotTooltip job={job} />
                            </div>
                        }
                    />
                }
            </Box>
        )
    } else {
        if (!isSelected) {

            return (
                <Box sx={{
                    display: "flex", flexDirection: "row"
                }}>

                    <Box
                        data-testid="planning-slot-ic"
                        data-slotinfo={`${interClient};${slot.slot}`}
                        sx={{
                            ...carTextStyle, pr: 0,
                            "& .MuiFormHelperText-root": { width: "130px", ml: 0.5 },
                            "& .MuiOutlinedInput-root": { pr: 0 },
                            borderRadius: "4px",
                            textAlign: 'center',
                            border: '1px solid rgba(0,0,0,0.33)',
                            width: !!job ? carTextStyle.width - 24 : carTextStyle.width,

                        }}
                        onDrop={(e) => {
                            e.preventDefault();
                            selectChange(e, props.dragItem || null, slot);
                        }}

                        onDragOver={(e) => {
                            //Default dragOver function to prevent it from making onDrop function work
                            let event = e;
                            if (disabled) {
                                return
                            } 
                            event.stopPropagation();
                            event.preventDefault();
                            
                        }}
                        onClick={(e) => {
                            setIsSelected(true);
                            console.log(job)
                        }}

                        className={!job ? "" : "default-red"}
                    >

                        {
                            !!job &&
                            <Typography sx={{ fontSize: "0.68rem", overflow: "hidden", overflowWrap: "break-word" }} >
                                <s>
                                    {job.item.shortName}
                                </s>
                            </Typography>
                        }


                    </Box>
                    {
                        !!job &&
                        <Tooltip
                            slotProps={{ tooltip: { sx: { maxWidth: "12.5rem" }}}}
                            title="Alihankintakuorman poisto täytyy vielä suorittajan järjestelmän kautta lähettää autolle">
                            <Info/>
                        </Tooltip>
                    }
                </Box>
            )
        }
        return (
            <Autocomplete
                options={optionsLoading ? [{ id: -1 }, ...options] : options}
                freeSolo
                disableClearable={true}
                getOptionLabel={(option: LogitarItem | string) => typeof option !== 'string' ? `${option.id} ${option.name}` : ""}
                PopperComponent={CustomPopper}
                size="small"
                filterOptions={(o) => o} // Disable filtering, handled by API call search param
                getOptionDisabled={(option: LogitarItem) => {
                    if (!interClient) return false;
                    return !option.interClients?.includes(interClient)
                }}

                onChange={(e, values) => {
                    if (typeof values !== "string") selectChange(e as ChangeEvent<Element>, { item: values as any }, slot);
                }}
                componentsProps={{
                    paper: {
                        elevation: 10,
                    },
                }}

                renderOption={(props, option, state, ownerState) => (
                    option.id < 0 ?
                    <Box {...props as BoxProps} component="li" style={{ padding: 0 }}
                        sx={{ pointerEvents: "none", position: "absolute", top: 0, right: 0, left: 0 }}>
                        <LinearProgress sx={{ width: "100%" }} />
                    </Box>
                    :
                    <Box
                        {...props as BoxProps}
                        component="div"
                        sx={option.active ? {} : { opacity: 0.5, textDecoration: "line-through", position: "relative" }}
                    >
                        <Box title={`${ownerState?.getOptionLabel(option)} ${option.cargoLabel} ${option.itemNumber}`}
                            component="span" sx={{ ...itemOptionStyle }}>{ownerState?.getOptionLabel(option)}
                            <i style={{ marginInlineStart: "8px", opacity: 0.6 }}>{`${option.cargoLabel} ${option.itemNumber}`}</i>
                        </Box>
                        {!option.active &&
                            <Box sx={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }} onClick={(e) => { window.open(`items?id=${option.id}&passive`, "_blank"); e.stopPropagation() }} />
                        }
                    </Box>
                )}

                renderInput={(params) =>
                    <TextField
                        {...params}
                        onChange={searching}

                        InputProps={{
                            ...params.InputProps,
                            style: {
                                padding: 0,
                            },

                        }}

                        onBlur={() => setIsSelected(false)}

                        inputRef={autocompleteRef}

                        onDrop={(e) => {
                            e.preventDefault();
                            selectChange(e, props.dragItem || null, slot);
                        }}

                        onDragOver={(e) => {
                            //Default dragOver function to prevent it from making onDrop function work
                            let event = e;
                            event.stopPropagation();
                            event.preventDefault();
                        }}
                        type="search"
                        sx={{ ...carTextStyle }}
                        size='small'

                        onKeyDown={handleJobKeyInput}
                    />}
            />
        )
    }
}

const MemoizedSlot = memo(PlanningRowSlot, (prev, next) => {
    if(prev.dragItem !== next.dragItem) return false;
    if (!prev.slot.job && !next.slot.job) return true;
    if (!prev.slot.job || !next.slot.job) return false;

    return prev.slot.job.state === next.slot.job.state &&
        prev.slot.date === next.slot.date &&
        prev.slot.shift === next.slot.shift &&
        prev.slot.job.draft === next.slot.job.draft;
});

export interface PlanningICRowProps {
    planRowInfo: PlanningRowIC;
    dragItem?: DragItem | null;
    interClient?: string;
    onStartDrag: (e: DragEvent, slot: PlanningSlot) => void;
}


function PlanningICRow (props: PlanningICRowProps) {

    const [planRow, setPlanRow] = useState(props.planRowInfo);
    const update = useContext(UpdateContext);

    const theme = useTheme();
    const lgBreakPoint = useMediaQuery(theme.breakpoints.up("lg"));
    const smBreakPoint = useMediaQuery(theme.breakpoints.up("sm"));
    //Send cars plans
    const sendIC = (row: PlanningRowIC) => {

        //Create jobs
        const jobs: any[] = []
        console.log(row.slots);
        row.slots.forEach((value) => {

            if (!!value.job) {
                jobs.push({ id: value.job.id, ">commit": true })
            }
        })

        if (jobs.length < 1) {
            return;
        }

        LogitarApi.setJobs(jobs).then((r) => {
            console.log(r);
            update(jobs);
        })
            .catch((e) => console.error(e))
    }

    useEffect(() => {
        setPlanRow(props.planRowInfo);
    }, [props.planRowInfo])

    return (
        <Box
            sx={{
                "& .MuiLinearProgress-dashed": {
                    animation: "none"
                },
                "& 	.MuiLinearProgress-bar1Buffer ": {
                    backgroundColor: "green"
                },
                "& .MuiOutlinedInput-input": {
                    py: 0,
                    pl: 1,
                    height: 40
                }
            }}>
            {planRow &&
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    {
                        <Box sx={{ flexDirection: 'column', width: "8.5rem", maxWidth: smBreakPoint ? undefined : 100, display: 'flex', flex: 0.5 }}>
                            <Typography sx={carHeaderStyle}>
                                {planRow.client.displayName}
                            </Typography>
                            <Typography sx={{ color: '#777' }} variant="caption">

                            </Typography>
                        </Box>
                    }

                    <Box sx={{ justifyContent: "space-between", flexDirection: "row", display: "flex" }}>


                        <Box sx={rowStyle}>
                            {
                                planRow.slots.map((slot, key) => {
                                    return <MemoizedSlot 
                                        slot={slot}      
                                        dragItem={props.dragItem} 
                                        key={key} 
                                        interClient={props.interClient} 
                                        onStartDrag={(e) => props.onStartDrag(e, slot)} 
                                    />
                                })
                            }
                        </Box>

                        <Box
                            sx={{
                                "& .MuiTypography-root": {
                                    fontSize: 12
                                },
                                ml: 2,
                                minWidth: lgBreakPoint ? 220 : 112, // Match normal PlanningCarRow width
                                flexDirection: 'row',
                                display: 'flex'
                            }}
                        >
                            <Box
                                sx={{ flexDirection: 'column' }}
                            >
                            </Box>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>
    )
}

export default PlanningICRow;