import { InfoOutlined, ReportOutlined } from "@mui/icons-material";
import { FuelType, fuelTypeList } from "../FuelPriceCard";
import { Box, CircularProgress, Stack, Tooltip } from "@mui/material";
import { GridRenderCellParams, GridValueFormatterParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { EmissionsAverage, JobDistancesJob, VehicleExternalData } from "../../misc/LogitarTypes";
import { ReactElement, useState } from "react";
import LogitarApi from "../../api/LogitarApi";

export type EmissionReportJob = {
    id: number,
    billingDate: string,
    item: number,
    itemName: string,
    client: number,
    departureName: string,
    arrivalName: string,
    clientName: string,
    vehicle: number,
    vehicleLicense: string,
    tons?: string,
    loadStart?: string,
    unloadEnd?: string,
    nextLoadStart?: string,
    fuelType: number,
    distanceTotal?: number,
    distanceJob?: number,
    fuelTotal?: number,
    fuelJob?: number,
    cargoLabel: string,
    dataFromAverage?: boolean,
}

export type EmissionsJobInfo = {
    id: number,
    nextId?: number,
    item: number,
    nextItem?: number,
    date: string,
    shift: "A" | "I",
    slot: number,
    nextDate?: string,
    nextShift?: "A" | "I",
    nextSlot?: number,
    loadStart?: string,
    unloadEnd?: string,
    nextLoadStart?: string,
    nextUnloadEnd?: string,
    driverLoadTime?: string,
    driverUnloadTime?: string,
    driverNextLoadTime?: string,
    driverNextUnloadTime?: string
}

export type EmissionSummary = {
    totalTons: number,
    totalKm: number,
    totalFuel: number,
    totalCO2: number,
    CO2PerKm: number,
    CO2PerTons: number,
}

type InvalidReason =
    "fuelNull" |
    "fuelNegative" |
    "nextLoadBeforeUnloadGps";

export type ReportConfig = {
    range: Date[],
    clientName?: string,
    clients: number[],
}

type VehicleExternalDataResponse = { vehicles: { id: number, licenseNumber: string, data: VehicleExternalData[] }[], status: boolean };

export function calculateEmissionSummary(reportJobs: EmissionReportJob[], multipliers: { type: FuelType, perUnit: number }[], reportConfig: ReportConfig) {
    const totals: EmissionSummary = {
        totalFuel: 0,
        totalTons: 0,
        totalKm: 0,
        totalCO2: 0,
        CO2PerKm: 0,
        CO2PerTons: 0,
    }
    reportJobs.forEach(job => {
        // Calculate summary data only if valid data is available
        if (isJobInvalid(job, false) !== false) return;
        totals.totalFuel += job.fuelTotal && job.fuelTotal > 0 ? job.fuelTotal : 0;
        totals.totalTons += Number(job.tons) > 0 ? Number(job.tons) : 0;
        totals.totalKm += job.distanceTotal && job.distanceTotal > 0 ? job.distanceTotal : 0;
        const fuelMultiplier = multipliers.find(e => e.type === job.fuelType);
        totals.totalCO2 += job.fuelTotal && fuelMultiplier ? job.fuelTotal * fuelMultiplier.perUnit : 0;
    })
    totals.CO2PerKm = totals.totalKm > 0 ? totals.totalCO2 / totals.totalKm : 0;
    totals.CO2PerTons = totals.totalTons > 0 ? totals.totalCO2 / totals.totalTons : 0;

    return ({ ...reportConfig, ...totals });
}

export function isJobInvalid(job: EmissionReportJob, checkTimes: boolean) {
    const reasons: InvalidReason[] = [];
    if (job.fuelTotal == null) reasons.push("fuelNull");
    if (job.fuelTotal && job.fuelTotal < 0) reasons.push("fuelNegative");
    if (checkTimes) {
        if (job.unloadEnd != null && job.nextLoadStart != null &&
            new Date(job.unloadEnd) > new Date(job.nextLoadStart)) reasons.push("nextLoadBeforeUnloadGps");
    }
    return reasons.length > 0 ? reasons : false;
}

export function combineJobsAndAverages(jobs: EmissionReportJob[], averages: EmissionsAverage[]) {
    return jobs.map(j => {
        if (isJobInvalid(j, true) !== false) {
            const avg = averages.find(a => a.item === j.item && a.fuelType === j.fuelType);
            return {
                ...j,
                distanceTotal: avg?.avgTotalKm ? avg.avgTotalKm : j.distanceTotal,
                distanceJob: avg?.avgJobKm ? avg.avgJobKm : j.distanceJob,
                fuelTotal: avg?.avgTotalFuel ? avg.avgTotalFuel : j.fuelTotal,
                fuelJob: avg?.avgJobFuel ? avg.avgJobFuel : j.fuelJob,
                fuelType: avg?.fuelType ? avg.fuelType : j.fuelType,
                dataFromAverage: true
            }
        }
        return j;
    })
}

export function RowInvalidReason({ params, showDetails }: { params: GridRenderCellParams, showDetails: boolean }) {
    const rowJob: EmissionReportJob = params.row;

    const [jobInfo, setJobInfo] = useState<EmissionsJobInfo | null>(null);
    const [status, setStatus] = useState<"initial" | "loading" | "ok" | "failed">("initial");

    const fetchEmissionsJobInfo = () => {
        if (jobInfo === null && status === "initial") {
            setStatus("loading");
            LogitarApi.getEmissionsInfo(rowJob.id).then(res => {
                const typedRes = res as { job: EmissionsJobInfo, status: boolean };
                if (typedRes?.job != null) {
                    setJobInfo(typedRes.job);
                    setStatus("ok");
                } else {
                    setStatus("failed");
                }
            }).catch(err => {
                console.error(err);
                setStatus("failed");
            });
        }
    }

    const invalidReasons = isJobInvalid(rowJob, false);
    let error: string[] = [];
    if (invalidReasons !== false) {
        invalidReasons.map(reason => {
            switch (reason) {
                case "fuelNull":
                    error.push("Polttoaineen kulutus puuttuu");
                    break;
                case "fuelNegative":
                    error.push("Polttoaineen kulutus on negatiivinen: " + rowJob.fuelTotal);
                    break;
                case "nextLoadBeforeUnloadGps":
                    error.push("Seuraavan kuorman GPS lastausaika on ennen tämän purkua")
                    break;
                default:
                    break;
            }
        })
    }

    const jobData = status === "loading" ?
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <CircularProgress />
        </Box>
        : status === "failed" ?
            <Box sx={{ color: "error.main" }}>Tietojen hakeminen epäonnistui</Box>
            : status === "ok" && jobInfo !== null ? <Stack gap={1} direction={"column"}>
                <Stack gap={3} direction={"row"}>
                    <Stack direction={"column"}>
                        <Box sx={{ textAlign: "center", mb: 0.5, fontWeight: "bold" }}>Tämä kuorma</Box>
                        <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
                            <Box>
                                <Box>Kuorman ID:</Box>
                                <Box>Nimikkeen ID:</Box>
                                <Box>Suunnitelupäivä:</Box>
                                <Box>Vuoro:</Box>
                                <Box>Kuormanumero:</Box>
                                <Box>Lastaus GPS:</Box>
                                <Box>Purku GPS:</Box>
                                <Box>Lastaus kuljettaja:</Box>
                                <Box>Purku kuljettaja:</Box>
                            </Box>
                            <Box>
                                <Box>{jobInfo.id}</Box>
                                <Box>{jobInfo.item}</Box>
                                <Box>{format(new Date(jobInfo.date), "dd.MM.yyyy")}</Box>
                                <Box>{formatShift(jobInfo.shift)}</Box>
                                <Box>{jobInfo.slot + 1}</Box>
                                <Box>{jobInfo.loadStart ? format(new Date(jobInfo.loadStart + "Z"), "dd.MM. HH:mm") : "–"}</Box>
                                <Box>{jobInfo.unloadEnd ? format(new Date(jobInfo.unloadEnd + "Z"), "dd.MM. HH:mm") : "–"}</Box>
                                <Box>{jobInfo.driverLoadTime ? format(new Date(jobInfo.driverLoadTime + "Z"), "dd.MM. HH:mm") : "–"}</Box>
                                <Box>{jobInfo.driverUnloadTime ? format(new Date(jobInfo.driverUnloadTime + "Z"), "dd.MM. HH:mm") : "–"}</Box>
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack direction={"column"}>
                        <Box sx={{ textAlign: "center", mb: 0.5, fontWeight: "bold" }}>Seuraava kuorma</Box>
                        <Stack gap={2} direction={"row"} justifyContent={"space-between"}>
                            <Box>
                                <Box>Kuorman ID:</Box>
                                <Box>Nimikkeen ID:</Box>
                                <Box>Suunnitelupäivä:</Box>
                                <Box>Vuoro:</Box>
                                <Box>Kuormanumero:</Box>
                                <Box>Lastaus GPS:</Box>
                                <Box>Purku GPS:</Box>
                                <Box>Lastaus kuljettaja:</Box>
                                <Box>Purku kuljettaja:</Box>
                            </Box>
                            <Box>
                                <Box>{jobInfo.nextId == null ? "–" : jobInfo.nextId}</Box>
                                <Box>{jobInfo.nextItem == null ? "–" : jobInfo.nextItem}</Box>
                                <Box>{jobInfo.nextDate == null ? "–" : format(new Date(jobInfo.nextDate), "dd.MM.yyyy")}</Box>
                                <Box>{jobInfo.nextShift == null ? "–" : formatShift(jobInfo.nextShift)}</Box>
                                <Box>{jobInfo.nextSlot == null ? "–" : jobInfo.nextSlot + 1}</Box>
                                <Box>{jobInfo.nextLoadStart ? format(new Date(jobInfo.nextLoadStart + "Z"), "dd.MM. HH:mm") : "–"}</Box>
                                <Box>{jobInfo.nextUnloadEnd ? format(new Date(jobInfo.nextUnloadEnd + "Z"), "dd.MM. HH:mm") : "–"}</Box>
                                <Box>{jobInfo.driverNextLoadTime ? format(new Date(jobInfo.driverNextLoadTime + "Z"), "dd.MM. HH:mm") : "–"}</Box>
                                <Box>{jobInfo.driverNextUnloadTime ? format(new Date(jobInfo.driverNextUnloadTime + "Z"), "dd.MM. HH:mm") : "–"}</Box>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack> : null;

    if (error.length > 0) {
        return (
            <Tooltip onOpen={() => fetchEmissionsJobInfo()} slotProps={{ tooltip: { sx: { maxWidth: 600 } } }} title={<Stack gap={1} direction={"column"}>
                <Box sx={{ color: "warning.main" }}>
                    <Box sx={{ fontWeight: "bold", textAlign: "center", fontSize: "0.9rem" }}>Riviä ei käytetä raportin laskennassa:</Box>
                    <Box sx={{ width: "max-content", mx: "auto" }}>
                        {error.map(e => <Box>• {e}</Box>)}
                    </Box>
                </Box>
                <Box sx={{ fontWeight: "bold", textAlign: "center", fontSize: "0.9rem" }}>Kuorman laskennan tiedot</Box>
                {showDetails && jobData}
            </Stack>}>
                <ReportOutlined color="warning" />
            </Tooltip>
        )
    }
    if (showDetails) {
        return <Tooltip onOpen={() => fetchEmissionsJobInfo()} slotProps={{ tooltip: { sx: { maxWidth: 600 } } }} title={
            <Box>
                <Box sx={{ fontWeight: "bold", textAlign: "center", fontSize: "0.9rem" }}>Kuorman laskennan tiedot</Box>
                {jobData}
            </Box>}>
            <InfoOutlined />
        </Tooltip>
    }
    return null;
}

export function EmissionsKmTooltip({ params, children }: { params: GridRenderCellParams, children: ReactElement }) {

    const [kmData, setKmData] = useState<JobDistancesJob | null>(null);
    const [status, setStatus] = useState<"initial" | "loading" | "ok" | "failed">("initial");

    const fetchJobDistances = () => {
        if (kmData === null && status === "initial") {
            setStatus("loading");
            LogitarApi.getJobDistances({ id: params.row.id }).then(res => {
                const typedRes = res as { jobs: JobDistancesJob[], status: boolean };
                if (typedRes?.jobs[0] != null) {
                    setKmData(typedRes.jobs[0]);
                    setStatus("ok");
                } else {
                    setStatus("failed");
                }
            }).catch(err => {
                console.error(err);
                setStatus("failed");
            });
        }
    }

    return (
        <Tooltip enterNextDelay={250} onOpen={() => fetchJobDistances()} slotProps={{ tooltip: { style: { maxWidth: 600 } } }}
            title={
                <Box>
                    <Box sx={{ fontWeight: "bold", textAlign: "center", fontSize: "0.8rem", mb: 0.5 }}>Kilometrien vertailu</Box>
                    {status === "loading" ?
                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <CircularProgress />
                        </Box>
                        : status === "failed" ?
                            <Box sx={{ color: "error.main" }}>Tietojen hakeminen epäonnistui</Box>
                            : status === "ok" && kmData !== null ?
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                        <Box>
                                            <Box sx={{ fontWeight: "bold", textAlign: "center" }}>Auto</Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Km A→B→C:</span>
                                                <span>{params.row.distanceTotal != null ? params.row.distanceTotal : "–"}</span>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Km A→B:</span>
                                                <span>{params.row.distanceJob != null ? params.row.distanceJob : "–"}</span>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box sx={{ fontWeight: "bold", textAlign: "center" }}>GPS</Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Km A→B→C:</span>
                                                <span>{kmData.gpsTransportKilometres != null && kmData.gpsTransitionAfterKilometres != null
                                                    ? Math.round(kmData.gpsTransportKilometres + kmData.gpsTransitionAfterKilometres)
                                                    : "–"
                                                }</span></Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Km A→B:</span>
                                                <span>{kmData.gpsTransportKilometres != null
                                                    ? Math.round(kmData.gpsTransportKilometres)
                                                    : "–"
                                                }</span>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "max-content", margin: "auto" }}>
                                        <Box sx={{ fontWeight: "bold", textAlign: "center" }}>Erot</Box>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                            <span>Km A→B→C:</span>
                                            <span>{params.row.distanceTotal != null &&
                                                kmData.gpsTransportKilometres != null && kmData.gpsTransitionAfterKilometres != null
                                                ? params.row.distanceTotal - Math.round(kmData.gpsTransportKilometres + kmData.gpsTransitionAfterKilometres)
                                                : "–"
                                            }</span>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                            <span>Km A→B:</span>
                                            <span>{params.row.distanceJob != null && kmData.gpsTransportKilometres != null
                                                ? params.row.distanceJob - Math.round(kmData.gpsTransportKilometres)
                                                : "–"}</span>
                                        </Box>
                                    </Box>
                                </Box>
                                : null
                    }
                </Box>
            }>
            {children}
        </Tooltip>
    )
}

export function EmissionsTimesTooltip({ params, children }: { params: GridRenderCellParams, children: ReactElement }) {
    const typedRow = params.row as EmissionReportJob;

    const vehicle = typedRow.vehicle;
    const timeA = typedRow.loadStart;
    const timeB = typedRow.unloadEnd;
    const timeC = typedRow.nextLoadStart;

    const [vehicleData, setVehicleData] = useState<{ load: VehicleExternalData | null, unload: VehicleExternalData | null, nextLoad: VehicleExternalData | null } | null>(null);
    const [status, setStatus] = useState<"initial" | "loading" | "ok" | "failed">("initial");

    const fetchVehicleExternalData = () => {
        if (vehicleData === null && status === "initial") {
            setStatus("loading");
            Promise.all([
                LogitarApi.getVehicleExternalData(vehicle, timeA),
                LogitarApi.getVehicleExternalData(vehicle, timeB),
                LogitarApi.getVehicleExternalData(vehicle, timeC),
            ]).then(res => {
                const typedRes = res as VehicleExternalDataResponse[];
                setVehicleData({
                    load: typedRes[0].vehicles?.[0]?.data?.[0] || null,
                    unload: typedRes[1].vehicles?.[0]?.data?.[0] || null,
                    nextLoad: typedRes[2].vehicles?.[0]?.data?.[0] || null
                });
                setStatus("ok");
            }).catch(err => {
                console.error(err);
                setStatus("failed");
            });
        }
    }

    return (
        <Tooltip enterNextDelay={250} onOpen={() => fetchVehicleExternalData()} slotProps={{ tooltip: { style: { maxWidth: 600 } } }}
            title={
                <Box>
                    <Box sx={{ fontWeight: "bold", textAlign: "center", fontSize: "0.8rem", mb: 0.5 }}>Aikaleimojen vertailu</Box>
                    {status === "loading" ?
                        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <CircularProgress />
                        </Box>
                        : status === "failed" ?
                            <Box sx={{ color: "error.main" }}>Tietojen hakeminen epäonnistui</Box>
                            : status === "ok" && vehicleData !== null ?
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                                        <Box>
                                            <Box sx={{ fontWeight: "bold", textAlign: "center" }}>Lastaukset/Purut</Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Aika A:</span>
                                                <span>{timeA != null ? format(new Date(timeA + "Z"), "dd.MM. HH:mm") : "–"}</span>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Aika B:</span>
                                                <span>{timeB != null ? format(new Date(timeB + "Z"), "dd.MM. HH:mm") : "–"}</span>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Aika C:</span>
                                                <span>{timeC != null ? format(new Date(timeC + "Z"), "dd.MM. HH:mm") : "–"}</span>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box sx={{ fontWeight: "bold", textAlign: "center" }}>Auton Tilannedata</Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Aika A:</span>
                                                <span>{vehicleData.load?.dateTime && timeA ? format(new Date(vehicleData.load.dateTime + "Z"), "dd.MM. HH:mm") : "–"
                                                }</span>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Aika B:</span>
                                                <span>{vehicleData.unload?.dateTime && timeB ? format(new Date(vehicleData.unload.dateTime + "Z"), "dd.MM. HH:mm") : "–"
                                                }</span>
                                            </Box>
                                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                                <span>Aika C:</span>
                                                <span>{vehicleData.nextLoad?.dateTime && timeC ? format(new Date(vehicleData.nextLoad.dateTime + "Z"), "dd.MM. HH:mm") : "–"
                                                }</span>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: "max-content", margin: "auto" }}>
                                        <Box sx={{ fontWeight: "bold", textAlign: "center" }}>Erot</Box>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                            <span>Aika A:</span>
                                            <span>{timeA != null && vehicleData.load?.dateTime != null ?
                                                printTimestampDifference(new Date(timeA), new Date(vehicleData.load.dateTime)) :
                                                "–"
                                            }</span>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                            <span>Aika B:</span>
                                            <span>{timeB != null && vehicleData.unload?.dateTime != null ?
                                                printTimestampDifference(new Date(timeB), new Date(vehicleData.unload.dateTime)) :
                                                "–"
                                            }</span>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
                                            <span>Aika C:</span>
                                            <span>{timeC != null && vehicleData.nextLoad?.dateTime != null ?
                                                printTimestampDifference(new Date(timeC), new Date(vehicleData.nextLoad.dateTime)) :
                                                "–"
                                            }</span>
                                        </Box>
                                    </Box>
                                </Box>
                                : null
                    }
                </Box>
            }>
            {children}
        </Tooltip>
    )
}

export function calculateEmissionsForFuel(job: EmissionReportJob, multipliers: { type: FuelType, perUnit: number }[]) {
    const fuelMultiplier = multipliers.find(e => e.type === job.fuelType);
    return job.fuelTotal != null && fuelMultiplier != null ? job.fuelTotal * fuelMultiplier.perUnit : null;
}

function formatShift(shift: "A" | "I") {
    return shift === "A" ? "Aamu" : shift === "I" ? "Ilta" : "–";
}

export function formatFuelValue(params: GridValueFormatterParams) {
    if (params.id) {
        const row = params.api.getRow(params.id); // valueFormatter doesn't get params.row
        return params.value && fuelTypeList.find(t => t.value === row.fuelType)
            ? params.value + " " + fuelTypeList.find(t => t.value === row.fuelType)?.unit : null
    }
    return null;
}

function printTimestampDifference(dateA: Date, dateB: Date) {
    let diff = (dateB.getTime() - dateA.getTime()) / 1000;
    const isNegative = diff < 0;
    diff = Math.abs(diff);
    const hours = Math.floor(diff / 3600);
    const minutes = Math.floor((diff % 3600) / 60);
    const seconds = Math.floor((diff % 60));
    return `${isNegative ? "-" : ""}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
}